<template>
  <div class="mytasks_iframe_wrap">
    <div style="display: none">这个页面在 /views/system/details/mytasks/detail.vue{{ VUE_APP_TRANSLATE_URL }}</div>
    <!-- <iframe
      :style="{ height: `${iframeHeight}px` }"
      class="mytasks_iframe"
      :src="`//${host}/mytasks/detail/${params.taskId}/${params.productionId}/${params.chapterId}?fromProduction=${fromProduction}&record=${params.record}`"
      frameborder="0"
    ></iframe> -->
    <my-task-new ref="myTaskNew"></my-task-new>
  </div>
</template>

<script>
import { addListenerMessage } from '@/utils/util'
import MyTaskNew from './modules/myTaskNew.vue'
export default {
  components: {MyTaskNew},
  data() {
    return {
      params: {
        ...this.$route.params,
        record: this.$route.query.record,
      },
      fromProduction: this.$route.query.fromProduction || 0,
      iframeHeight: 0,
      host: this.VUE_APP_TRANSLATE_URL,
    }
  },
  methods: {},
  created() {
    console.log(process.env.VUE_APP_TRANSLATE_URL)
    this.iframeHeight = window.innerHeight - 100
  },
  mounted() {
    addListenerMessage(this)
  },
}
</script>

<style lang="less">
.mytasks_iframe_wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.mytasks_iframe {
  width: 100%;
  height: 500px;

  overflow: auto;
}
</style>

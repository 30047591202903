var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mytasks_iframe_wrap" },
    [
      _c("div", { staticStyle: { display: "none" } }, [
        _vm._v(
          "这个页面在 /views/system/details/mytasks/detail.vue" +
            _vm._s(_vm.VUE_APP_TRANSLATE_URL)
        ),
      ]),
      _c("my-task-new", { ref: "myTaskNew" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }